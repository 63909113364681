import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CanActivate } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { NbAccessChecker, NbAclService } from '@nebular/security';
import { RestApiService } from 'app/services/rest-api.service';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';


@Injectable()
export class AccessCheckerGuard implements CanActivate {
  // acl = ACL;

  constructor(
    private router: Router,
    private accessChecker: NbAccessChecker,
    private aclService: NbAclService,
    protected restApi: RestApiService) {
    // aclService.setAccessControl(this.acl);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //  console.log(state);
      // console.log(route.routeConfig.path);
      // TODO: we do not need this right now, because if someone goes to a page through link,
      // data will not load, nor save because of API resitrctions
      // Also, ACL loading function is currently not global (local to pages component)
      // Still, probably would be nice to have ACL check here
     return true;

    // if (route.routeConfig.path == 'home') {
    //   return true;
    // }
    // if (route.routeConfig.path == 'pages') {
    //   return true;
    // }
    // if (route.routeConfig.path == '') {
    //   return true;
    // }

    // return this.accessChecker.isGranted('view', '/pages/' + route.routeConfig.path)
    //   .pipe(
    //     tap(granted => {
    //       if (!granted) {
    //         this.router.navigate(['pages/home']);
    //       }
    //     }),
    //   );

    // TODO: disabled for now, because edit routes do not work with this
    // return this.accessChecker.isGranted('view', state.url)
    //   .pipe(
    //     tap(granted => {
    //       if (!granted) {
    //         this.router.navigate(['pages/home']);
    //       }
    //     }),
    //   );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    return this.canActivate(route, state);
  }
}
