
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {environment} from '@project-environments/environment';
import { NbAuthOAuth2Token, NbAuthService } from '@nebular/auth';
import { takeUntil } from 'rxjs/operators';
import { __param } from 'tslib';

export enum ResourceNames {
    Oauth2Users = 'oauth2-users',
    Oauth2Resources = 'oauth2-resources',
    Oauth2Roles = 'oauth2-roles',
    Oauth2UserRoles = 'oauth2-user-roles',
    Oauth2RoleResources = 'oauth2-role-resources',
    Clients = 'clients',

    // fitsy
    Devices = 'devices',
    DeviceModels = 'device-models',
    DeviceRentals = 'device-rentals',
    DeviceRentalBinaryImages = 'device-rental-binary-images',
    DeviceRentalImages = 'device-rental-images',
    DeviceIcons = 'device-icons',
    DeviceIconBinary = 'device-icon-binary',
    DeviceQrCode = 'device-qr',
    Customers = 'customers',
    CustomerCards = 'customer-cards',
    Accounts = 'accounts',
    Regions = 'regions',
    Nests = 'nests',
    Rates = 'rates',
    RateTypes = 'rate-types',
    RateTypePrices = 'rate-type-prices',
    CustomerDocuments = 'customer-documents',
    CustomerDocumentImages = 'customer-document-images',
    CustomerDocumentBinaryImages = 'customer-document-binary-images',
    CustomerDocumentVerifications = 'customer-document-verifications',
    CustomerDocumentVerificationStatusHistory = 'customer-document-verifications-status-history',
    Settings = 'settings',
    DeviceSettings = 'device-settings',
    DeviceProblems = 'device-problems',
    Payments = 'payments',
    ManualPayments = 'manual-payments',
    TransactionRollback = 'transaction-rollback',
    Sms = 'sms',
    Brands = 'brands',
    Invoices = 'invoice',
}

@Injectable()
export class RestApiService {


    private destroy$ = new Subject<void>();
    token: NbAuthOAuth2Token;
    accessToken: string;

    constructor(private http: HttpClient, private authService: NbAuthService) {
        // TODO: maybe not the best place for this. Something called interceptors are apperantly cooler
        this.authService.onTokenChange()
        .pipe(takeUntil(this.destroy$))
        .subscribe((token: NbAuthOAuth2Token) => {
          this.token = null;
          if (token && token.isValid()) {
            this.token = token;
            if (token.getPayload() != null) {
              this.accessToken = token.getPayload()['access_token'];
            }
          }
        });
    }

    getHeaders() {
        return {
            Authorization: 'Bearer ' + this.accessToken,
        };
    }

    createIncludeParam(include?: any[]): string {
      let param = '';
      if ( include != null) {

        include.forEach((value) => {
          if (param !== '') {
            param += ',';
          }
          param += value;
        });
        param = '&include=' + param;
      }

      return param;
    }

    getResources(resourceName: string, include?: any[], filter?: Map<string, string>): Observable<any> {
      let url = environment.restApiUrl + '/' + resourceName + '?';

      //
      url += this.createIncludeParam(include);

      // if ( filter != null) {
      //   url += filter;
      // }
      if (filter != null) {
        filter.forEach((value: string, key: string) => {
          url += '&' + key + '=' + value;
        });
      }


      return this.http.get( url, { headers: this.getHeaders() });
    }

    getResource(resourceName: string, id: string, include?: any[]): Observable<any> {
      let url = environment.restApiUrl + '/' + resourceName + '/' + id + '?';

      url += this.createIncludeParam(include);

      return this.http.get(url, {headers: this.getHeaders()});
    }

    /**
     * only difference form getResource is responseType is set to arraybuffer
     *
     * @param resourceName
     * @param id
     * @param include
     * @returns
     */
    getResourceBinary(resourceName: string, id: string, include?: any[]): Observable<any> {
      let url = environment.restApiUrl + '/' + resourceName + '/' + id + '?';

      url += this.createIncludeParam(include);

      return this.http.get(url, {headers: this.getHeaders(), responseType: 'arraybuffer' });
    }

    postResource(resourceName: string, data): Observable<any> {
        return this.http.post(
            environment.restApiUrl + '/' + resourceName,
            { data },
            { headers: this.getHeaders() },
          );
    }

    putResource(resourceName: string, id: string, data): Observable<any> {
        return this.http.put(
            environment.restApiUrl + '/' + resourceName + '/' + id,
            { data },
            { headers: this.getHeaders() },
          );
    }

    deleteResource(resourceName: string, id: string): Observable<any> {
      const url = environment.restApiUrl + '/' + resourceName + '/' + id;

      return this.http.delete(url, { headers: this.getHeaders() });
    }
}
