import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'money',
})
export class MoneyPipe implements PipeTransform {
    locale = 'en-US';
    currency = 'EUR';

    transform(value: any, digits = 2): string {
        // TODO: null vs undefiend vs 0 vs empty string (== vs === vs if(value) vs  ?? vs whatever else there is)
        const money = value;
        if (value === undefined) {
            // money = 0;
            return '';
        }
        if (value === null) {
            return '';
            // money = 0;
        }

        return Intl.NumberFormat(
            this.locale,
            {
                style:'currency',
                currency: this.currency,
                minimumFractionDigits: digits,
            })
            .format(money/100);
    }

}
