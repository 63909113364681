import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { ResourceNames, RestApiService } from '../../../services/rest-api.service';
import { environment } from '@project-environments/environment';

@Component({
    selector: 'ngx-device-problems-counter',
    styleUrls: ['./device-problems-counter.component.scss'],
    template: `
    <nb-actions size="medium">
        <nb-action class="control-item device-problems-action" (click)="openDeviceProblems()">
            <nb-icon icon="alert-triangle-outline" pack="eva"></nb-icon>
            <span>{{deviceProblemCount}}</span>
        </nb-action>
    </nb-actions>
    `,
})
export class DeviceProblemsCounterComponent implements OnInit {
    loggedIn = false;
    interval;
    deviceProblemCount = 0;

    constructor(
        private toastrService: NbToastrService,
        protected router: Router,
        protected restApi: RestApiService) {
    }

    ngOnInit() {
        this.startDeviceProblemTimer();
    }

    startDeviceProblemTimer() {
      // Get first device problem count without waiting
      // // console.log('startTimer');
      // // // this.loggedIn = (localStorage.getItem('acl') !== null);
      // this.loggedIn = (localStorage.getItem('hasUser') === '1');
      // if (this.loggedIn) {
      //   this.refreshDeviceProblems();
      // }
      this.refreshDeviceProblems();

      this.interval = setInterval(() => {
        // // console.log('startTimer interval');
        // // // this.loggedIn = (localStorage.getItem('acl') !== null);
        // this.loggedIn = (localStorage.getItem('hasUser') === '1');

        // // timer
        // if (!this.loggedIn) {
        //   return;
        // }

        this.refreshDeviceProblems();
      }, environment.deviceProblemCountRefreshRate * 1000);
    }

    refreshDeviceProblems(){
        // console.log('refreshDeviceProblems');
        // find device problem count
        const filter  = new Map<string, string>();
        filter.set('page[number]', '1');
        filter.set('page[size]', '1');
        filter.set('filter[status]', 'new');

        this.restApi.getResources(ResourceNames.DeviceProblems, null, filter)
        .subscribe(
          data => {
            if (data.meta.itemCount > this.deviceProblemCount) {
              const title = 'Device Problems';
              const content = (data.meta.itemCount - this.deviceProblemCount) +  ` New device problem(s) registered!`;
              this.showToast('warning', title, content);
            }
            this.deviceProblemCount = data.meta.itemCount;
            // console.log(data.meta.itemCount);
          },
          error => {
            // console.log(error);
          });
    }

    private showToast(type: string, title: string, body: string) {
        const config = {
          status: type,
          // destroyByClick: this.destroyByClick,
          duration: 5000,
          // hasIcon: this.hasIcon,
          // position: this.position,
          // preventDuplicates: this.preventDuplicates,
        };

        this.toastrService.show(
          body,
          title,
          config);
    }

    openDeviceProblems(){
        this.router.navigate(['pages/device-problems']);
    }
}
